@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap');


/* ================================================ */
/* Init */
/* ================================================ */

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #000000;
    background:#ffffff;
    overflow-x:hidden;
}

a {
    -moz-transition: all .8s ease;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease;
    transition: all .8s ease;
    color: #444;
}

a > i {
    font-style: normal;
}

.wrapper {
    border-top: none;
}

.xm-grid-header > .container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.main {
    padding: 30px 0 0;
}

.container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

button.button {
    -webkit-border-fit: lines;
    overflow: visible;
    width: auto;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
}

button.button span {
    border-radius: 0px;
    margin:5px;
    background-color: #ce1141;
    color: #ffffff;
    padding: 5px;
    font-size: 15px;
    text-align: center;
    white-space: nowrap;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -o-transition: color .3s ease-in-out, background-color .3s ease-in-out;
}

.product-view .btn-cart:hover span,
button.btn-checkout:hover span,
button.button:hover span,
.product-view .btn-cart:active span,
button.btn-checkout:active span,
button.button:active span,  
{
    background-color: #ececec;
    color: #ce1141;
}

button.button span span {
    font-family: 'Open Sans', sans-serif;
}

.buttons-set button.button {
    margin-left: 0;
    margin-right: 5px;
    margin-bottom: 5px;
}

.button.btn-inline span, .button.btn-checkout span {
    background-color: #1b262c;
    color: #fff;
}

@media only screen and (min-width: 961px) {
    .show-below-960 {
        display: none !important;
    }
}

@media only screen and (max-width: 959px) {
    .hide-below-960 {
        display: none !important;
    }
}

/* ================================================ */
/* Font and Colours */
/* ================================================ */
.main-font, h1, h2, h3, h4, h5, h6, .section-title, .products-list .product-name, .products-grid .product-name, h3.product-name, .feature .heading, .accordion .heading, .nav-regular li.level0 > a, .nav-regular .nav-submenu--mega > li > a, .mobnav-trigger, .nav-mobile li.level0 > a, .nav-mobile li.level1 > a, .nav-mobile li.level2 > a, .block .block-title, #opc-login h3, .box-account .box-head h2, .order-items h2.table-caption, .order-items h2.sub-title, .order-items .order-comments h2, .product-view .box-reviews dt .heading, .gen-tabs .tabs a, .footer .heading, #subscribe-form label, .caption, .heading {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
}

#root-wrapper a:hover span.fa:before, .header-top a:hover, #nav .nav-panel--dropdown a:hover, p.required, .product-options dt label.required em, .form-list label.required em, a:hover, .vertnav li.current > a, .header .dropdown-menu a:hover, .header .open > .dropdown-toggle.cover > div a:hover, .header .form-search .search-autocomplete li:hover {
    color: #cd1042;
}

.main-container {
    background-color: #FFF;
    padding: 0 !important;
}

.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
    color: #444;
}

/* ================================================ */
/* Header */
/* ================================================ */


header#header.floating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: none;
}

.header .userCode,
.header .userName,
.header .userSwitch,
.header .userSwitch a,
.header .welcome-msg {
    font-size: 1.1em;
    color: #444444333 !important;
    margin: 5px 0 !important;
    overflow:unset;
    
}

.header .userSwitch {
    margin-left: 10px !important;
}

.header .userSwitch a,
.header .userSwitch a:hover {
    border-bottom: 1px solid #ececec;
}

.header-container {
    position: relative;
    border-bottom-width: 1px;
    border-bottom-color: #ececec;
    border-bottom-style: solid;
}

.header-primary {
    padding: 0;
    position: relative;
}

.header-top, .header .dropdown {
    line-height: 0;
}

.user-menu {
    margin: 0;
}

#mini-cart a {
    color: unset;
}

#mini-cart a:hover {
    color: unset;
}

#mini-cart .fa-shopping-cart {
	color: #ce1141;		
}
#mini-cart .fa-shopping-cart:hover {
	color: #ef567c;	
}

#mini-cart .dropdown-menu button span, .item-compare-widget span span {
	margin:5px;
	padding:2px !important;
	font-size:13px !important;
}

.item-compare-widget span span {
	height:auto !important;
	line-height:unset !important;
}

#mini-cart .dropdown-menu button span span {
	margin:5px;
	padding:2px;
	font-size:15px;
	line-height:unset;
	height:unset;
}

#mini-cart .actions {
	text-align:center;	
}
#mini-cart .actions button {
	float:unset;	
}
.dropdown-toggle * {
    display: inline-block;
}

.welcome-msg, .userCode, .userName, .userSwitch > a {
    color: #444444333;
}
header .header-top-nav {
	background:#f8f8f8;
	margin:0 auto;
	width:100%;
}

header .header-top-nav-contents {
	float:unset;
	display:inline-block;
	vertical-align:middle;
	margin:0 auto;
	padding:0;
	width:49%;	
}
.vertical-bar {
  display: inline-block;
  width: 1px;
  height: 15px; /* Adjust the height according to your needs */
  background-color: #000;
  margin: 0 10px; /* You can change the spacing if needed */
  vertical-align: middle; /* Aligns the bar with the middle of the text */
}
.links li {
	float:unset;
    display:inline-block;	
}
.header-top-nav-contents:nth-of-type(2) .header-top-container {
  text-align:right;
}
.module-basket-summary .feature-icon-hover a *:not(span) {
  display: none;
}
.module-basket-summary .dropdown-toggle {
	padding:0 5px;
}
.nav-item--home {
	display:none !important;	
}
.nav-container .level-top {
	float:unset !important;
	display:inline-block;
}
.inline-search-results li * {
	margin-bottom:5px;
	line-height:1.5em;
	width:89%;
	text-align:left;
}
.inline-search-results {
	top:42px !important;	
}
.inline-search-results li {
	display:inline-block;
	width:100%;
	min-height:auto !important;
	padding: 0 0 5px 0 !important;
	margin-left:10px;
}
.inline-search-results img {
	display:none;
}
.sat-logo {
	margin:0 auto;	
}
.header-desktop .nested-container {
	margin: 0 auto !important;	
	width:99%;
	float:unset !important;
}

.module-search .form-search .input-text {
	display:inline-block;
	width: auto !important;
	color:#444444;	
}

.quick-compare .hide-below-1280 {
	display:none !important;	
}

.header .item-right {
	margin:0 auto;	
}

#search {
	display:none;	
}

.module-search .form-search button {
		
}
.sat-logo,.sat-category-menu{
	float:unset;
	display:inline-block;
	vertical-align:middle;
}
.sat-logo {
	margin-left:15px;
	width:auto;	
}
.nav-regular li.level0>a {
	padding:0 5px;	
}
.sat-header-mm {
	text-align: right;
    float: right;
    margin-top: 15px;
}
.header-desktop .module-logo {
	text-align:left;	
}
.header-desktop .logo {
	width:auto;
	height:auto;	
}
.fa-shopping-cart {
	padding:0 !important;
	font-size:20px;
}
.header-top-container .fa {
	font-size:20px !important;
}

.my-account .page-title {
	text-align:center;
	border:0;
}

.block-account .block-title {
	background:unset;	
}

.my-account h1 {
	float:unset;
	text-align:center;	
}

.my-account-sidebar {
	text-align:left;	
}

.my-account-sidebar li {
	line-height:0;	
}

.my-account-sidebar li a {
	padding:5px 10px;
	text-transform:unset;
}

/* ================================================ */
/* Menu */
/* ================================================ */
#nav .nav-panel--dropdown a .caret {
	border-left-color: #cd1042;
    border-right-color: #cd1042;
    opacity:100;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #fff;
    border-right-color: #fff;
}

.nav-regular li.level0>a {
	font-size:14px;	
}
.nav.has-outline>.grid-full {
    margin-top: 0;
    margin-bottom: 0;
}
#nav {
    z-index: 1000;
    position: relative;
}

#nav, .mobnav-trigger {
    border-bottom: none;
}

.mobnav-trigger,
.mobnav-trigger.active,
.mobnav-trigger:hover {
    background-color: transparent;
}

.nav-regular {
    background-color: transparent;
}

#nav.nav-mobile ul.level0 .nav-item.current > a, #nav.nav-mobile ul.level0 .nav-item > a:hover, .cms-index-index .nav-regular .nav-item--home > a, .nav-mobile .nav-item.level0.current > a, .nav-mobile .nav-item.level0 > a:hover, .nav-regular .nav-item.level0.active > a, .nav-regular .nav-item.level0:hover > a {
    background-color: transparent;
}

.nav-container a, .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular a, .mobnav-trigger a {
    color: #444444;
}

.nav-regular .nav-item.level0:hover > a, .nav-mobile .nav-item.level0 > a:hover {
    color: #cd1042 !important;
    background-color: #fff;
    border-bottom: #cd1042;
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

.nav-item .level-top {
	border-bottom: #fff;
    border-bottom-width: 4px;
    border-bottom-style: solid;
}

.nav-item.active {
    background-color: #fff;
}
.nav-item.active > a,
.module-sub-categories-title.active{
    color: #cd1042 !important;
}
.nav-item.active > a .caret {
    border-top-color: #cd1042 !important;
}

.main-font, h1, h2, h3, h4, h5, h6, .section-title, .products-list .product-name, .products-grid .product-name, h3.product-name, .feature .heading, .accordion .heading, .nav-regular li.level0 > a, .nav-regular .nav-submenu--mega > li > a, .mobnav-trigger, .nav-mobile li.level0 > a, .nav-mobile li.level1 > a, .nav-mobile li.level2 > a, .block .block-title, #opc-login h3, .box-account .box-head h2, .order-items h2.table-caption, .order-items h2.sub-title, .order-items .order-comments h2, .product-view .box-reviews dt .heading, .gen-tabs .tabs a, .footer .heading, #subscribe-form label, .caption, .heading {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}
h3 {
    font-size: 16px;
}
.products-grid .product-name, .products-list .product-name, h3.product-name {
    font-size: 16px;
}

.block .block-title {
    border-bottom: none;
    padding-left: 10px;
    position: relative;
}

.block .block-title .mh-filters-btn {
    position: absolute;
    right: 0;
    top: 0;
}

.block .block-title .menu-icon {
    padding: 0 10px !important;
}

.sorter .amount {
    color: #444444333;
}

.nav-regular li.level0 > a {
    line-height: 70px;
}

.nav-regular li.level0 > a > span {
    display: inline-block;
    white-space: nowrap;
}

#nav .nav-item.level0.parent > a .caret {
    border-top-color: #fff;
}

#nav .nav-item.level0.parent:hover > a .caret {
    border-top-color: #cd1042;
}

.nav-regular .mega > .nav-panel--dropdown, .nav-regular li.level0 > .nav-panel--dropdown {
    border-top: none;
    width: 100vw !important;
}

.nav-regular li.level0 > .nav-submenu.nav-panel--dropdown {
    width: 16em !important;
}

.nav-mobile.opt-sb2 li.level0>div>div>.nav-block {
    padding: 0 10px;
}

.nav-mobile .opener:before {
    content: "\f107" !important;
    padding: 10px 10px 10px 20px !important;
}

.nav-mobile .opener.active:before, .nav-mobile li.active > .opener:before {
    content: "\f106" !important;
}

.nav-search.fly-out {
    display: none;
}

.nav-search .form-search .button-close {
    display: none;
}

.form-search .button i {
    font-style: normal;
}

#breadcrumbs {
	text-align:left;
}

.product-img-column {
    border: #ececec;
    border-style: solid;
    border-width: 1px;
}

.sat-product-details .sticker-wrapper.top-right {
	left: 16px;
    top: 5px;
    right: 0;	
}

.sat-product-details {
	margin:15px 2.5%;	
}

.sat-product-details .secondary-desc {
	text-align:left;	
}

#breadcrumbs li, #breadcrumbs li a {
	color:#444;	
}

.breadcrumb-separator {
	margin:0 2px;
	color:#444;
}

.breadcrumbs .nav-filter {
    float: right;
    font-size: 16px;
    font-weight: 700;
}
#filter_list_box dt {
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
}
.category-products .toolbar {
    border: none;
    width: 100%;
    text-align: right;
}
.category-products .toolbar .sorter {
    display: inline-block;
}
.mini-products-list li {
    list-style: none;
}
.breadcrumbs li {
    display: inline-block;
    vertical-align: top;
}
.sat-product-details  .product-name {
	text-align:left;
}
.product-shop h1 {
    font-size: 40px;
}
.product-shop .qty-wrapper label {
    display: none;
}
.col1-layout .col-main, .main-container .top-banner {
	text-align:center;	
}
.cart-body,.opc-progress-container,.opc-step {
	text-align:left;	
}
.module-sub-categories > .nav-item {
	display:inline-block;
	width:33%;
	margin:10px auto;
	vertical-align:top;
	font-size:13px;
}

.module-sub-categories .module-sub-categories-title {
	text-transform:unset !important;	
}

.module-sub-categories > .nav-item > .module-sub-categories-title {
	font-weight: 600;
}

.module-sub-categories ul:not(:first-child) li {
	background:unset !important;	
}

.module-category-misc .item-left {
	margin:0 15px !important;	
}

.add-to-links span.icon-hover {
	color:#fff;
	background:#000;
	border-radius:25px !important;	
}

.add-to-links span.icon-hover:hover {
	background:#ffffff;	
}

.sat-product-details .compare {
	list-style:none;	
}
.sat-product-details .compare .span-title {
	display:none;	
}
.sat-product-details .compare .fa {
	font-size:0.68em !important;	
}

@media only screen and (max-width: 960px) {
    .form-list .field,
    .form-list input.input-text,
    .form-list select,
    .progress, div[class^=strength_meter_passwd] {
        width: 100%;
    }
    
    .nav-item.active > a,
    .module-sub-categories-title.active{
        color: #cd1042 !important;
    }
    .nav-item.active > .opener {
        color: #fff !important;
    }
    .xm-grid-product .grid-left,
    .xm-grid-product .grid-right {
        width: 100% !important;
    }
    .product-shop h1,
    .product-shop .product-type-data {
        text-align: center !important;
    }
    .grid-col2-sidebar,
    .product-search-container .sidebar-category,
    .product-search-container .has-sidebar.grid-col2-main {
        width: 100%;
    }
    .details-main-container .breadcrumbs {
        display: none;
    }
    .sorter .sort-by {
        display: none;
    }
    .links>li>a {
        padding: 0 10px;
    }
    .product-shop {
        margin-top: 20px;
    }
    .sidebar-category .block-content {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    .sidebar-category .accordion{
        overflow-x: scroll;
        width: max-content;
    }
    .mm-panels .sidebar-category .accordion {
        width: 100% !important;
    }
    .sidebar-category .accordion li{
        display: inline-block;
        width: auto !important;
    }
    .mm-panels .sidebar-category .accordion li{
        display: block;
        width: 100% !important;
        text-align: left;
    }
    .grid-full, .grid12-12 {
        overflow: auto;
    }
    .nav-item .header-top-container {
        background-color: #fff;
    }
    .search-wrapper-mobile {
        width: 36px !important;
        height: 36px;
        margin: 0 !important;
        padding: 0;
        float: none;
        vertical-align: top;
    }
    .search-wrapper-mobile .form-search input {
        display: none;
    }
    .mobnav-trigger-wrapper {
        display: inline-block !important;
        vertical-align: top;
        float: none !important;
        padding: 10px .5em !important;
    }
    .mobnav-trigger {
        padding: 0;
    }
    .mobnav-trigger > span:last-child {
        display: none;
    }
    .mobnav-trigger .trigger-icon {
        padding-top: 0;
        margin: 0;
    }
    .mobnav-trigger .trigger-icon .line {
        background-color: #fff;
    }
    .search-wrapper-centered {
        float: none !important;
        display: inline-block !important;
    }
    #mini-cart .feature-icon-hover .hide-below-960,
    #mini-cart .feature-icon-hover .empty,
    nav .feature-icon-hover .hide-below-960,
    nav .feature-icon-hover .empty{
        display: none;
    }
    nav .feature-icon-hover {
        padding: 0 !important;
    }
    nav .feature-icon-hover .caret {
        display: none;
    }
    nav.nav {
        text-align: right;
        height: 36px;
    }
    nav.nav .header-top {
        margin: 0;
        padding: 0;
        width: 100%;
        display: block !important;
    }
    .nav-mobile {
        text-align: left;
    }
    .nav-logo, .nav-cart, .nav-search {
        display: inline-block;
        vertical-align: top;
    }
    .nav-logo {
        float: left;
        width: 200px;
        margin-top: 5px;
    }
    .nav-cart {
        height: 36px;
    }
    .nav-cart .fa-shopping-cart {
        font-size: 1.5em !important;
        font-style: normal;
    }
    .nav-cart .hide-below-960 {
        visibility: hidden;
        position: relative;
        width: 0;
        height: 0;
        display: block !important;
    }
    .nav-cart .hide-below-960 .cart-total {
        visibility: visible;
        position: absolute;
        left: 25px;
        top: -35px;
        border: none;
        background-color: #cd1042;
        border-radius: 50%;
        color: #fff;
        width: 17px;
        height: 17px;
        text-align: center;
        font-size: 9px;
        line-height: 17px;
    }
    .nav-cart .product-details a {
        color: #888;
    }
    .nav-cart .btn-remove,
    .nav-cart .btn-edit {
        float: right;
    }
    .nav-cart .empty, .nav-cart .caret {
        display: none;
    }
    
    .nav-search .input-text {
        display: none;
    }
    .nav-search .button {
        height: 36px;
    }
    .cart-mobile {
        display: inline-block !important;
        vertical-align: top;
    }
    .nav-item .header-top {
        display: block !important; 
    }
    .header-top .item {
        display: block;
    }
    .item-compare-widget, .item-min-cart {
    	width:auto !important;	
    }
    .nav-mobile .nav-block {
        display: initial;
    }
    .nav-mobile .nav-block,
    .nav-mobile .nav-block > .grid12-12 {
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
    }
    .nav-mobile .nav-block > .grid12-12 {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .module-sub-categories .nav-item {
        width: 100% !important;
    }
    .module-sub-categories .nav-item > a {
        padding: 15px 54px 15px 20px !important;
        text-transform: uppercase !important;
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    .module-sub-categories .nav-item .page-item {
        padding: 0 !important;
    }
    .module-sub-categories .nav-item .page-item > a {
        padding: 15px 10px 15px 40px !important;
        display: block;
        text-transform: uppercase;
    }
    .acco .module-sub-categories div.nav-item ul li {
        padding-left: 0 !important;
    }
    .nav-mobile .nav-panel-inner .opener {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .nav-mobile li.nav-item {
        font-size: 14px;
        background-color: #f7f7f7;
    }
    .nav-mobile .nav-item.parent a {
        background-color: transparent;
        border-bottom: 1px solid #e5e5e5;
        font-size: 14px;
        font-weight: 400;
    }
    .header-container .col-main {
        margin-bottom: 0;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .nav-item.show-below-960 {
        padding: 10px !important;
    }
    .nav-item.show-below-960 > div {
        display: block !important;
        padding: 0.3em 1em;
    }
    .nav-search.fly-out {
        position: fixed;
        display: block !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        width: 100% !important;
        background-color: #fff;
    }
    .nav-search.fly-out .form-search {
        position: relative;
        padding: 10px;
    }
    .nav-search.fly-out .form-search .input-text {
        width: 100%;
        max-width: 100%;
        display: block;
        border: none;
        padding-right: 70px;
    }
    .nav-search.fly-out .form-search .button {
        color: #444444333;
        top: 10px;
        right: 10px;
    }
    .nav-search.fly-out .form-search .button-search {
        right: 45px;
    }
    .nav-search.fly-out #inline-search-results {
        top: 50px;
    }
    .nav-search.fly-out .form-search .button-close {
        display: block;
    }
}
@media (max-width: 600px) {
    #my-menu {
        display: none !important;
    }
}
/* ================================================ */
/* Content: Logo and Search  */
/* ================================================ */
.header .logo-wrapper {
    text-align: center;
    margin: 10px 0;
}

.header .logo-wrapper a.logo {
    display: block;
}

.search-wrapper-centered {
    margin-top: 18px;
    margin-bottom: 7px;
}

.search-wrapper-centered .form-search {
    margin: 0 auto;
}

.search-wrapper-centered .form-search label {
    display: none;
}


.form-search .button {
    position: relative !important;
	float:unset !important;
}

@media only screen and (min-width: 768px) {
    .logo-wrapper > a {
        margin: 0 auto !important;
    }
}

/* ================================================ */
/* Content: Product Listing  */
/* ================================================ */

/* ================================================ */
/* Content: Product Detail  */
/* ================================================ */

.matrix-table .price-box .regular-price .price {
    font-size: 1em;
}

.product-shop .btn-cart span span {
    padding: 5px 40px;
    font-size: 1.1666em;
}

.product-shop .z4 {
    background-color: #cd1042 !important;
}

.img-box-style1 .product-image {
    border: none;
    padding: 0;
}

.product-shop h1 {
    color: #000000;
}
.product-shop ul {
    list-style: disc inside;
}

.product-shop .product-options dd {
    padding-left: 0;
}

.price-box .regular-price .price, .price-box .minimal-price .price, .price-box-bundle .full-product-price .price {
    color: #444;
    letter-spacing: 0.5px;
    font-family: 'Open Sans', sans-serif;
}

.product-options dt label, .product-shop .qty-wrapper label {
    font-size: 1.3em;
}

.product-shop button.add-cart-button {
    margin-right: 5px !important;
    margin-left: 5px;
}

.product-shop button.add-cart-button span {
    width: 225px;
    padding: 7px 0;
    margin: 0 auto;
    color: #fff;
    background-color:#CE1141;
    overflow:hidden;
    font-size:18px;
    font-weight:600;
}
.favorites .span-title {
	display:none;	
}
.product-shop button.add-cart-button:hover span,
.product-shop button.add-cart-button span:hover {
    background-color: #5c5c5c !important;
}

.product-shop .short-description {
    font-size: 16px;
    line-height: 1.75;
    padding-top: 32px;
    padding-bottom: 24px;
    font-weight: 100;
}

.product-shop ul li {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop p {
    font-size: 22px;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop .product-type-data {
    text-align: left;
}

.variations .swatches-con.list, .variations .swatchesCon.list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.variations .swatches-con.list .swatch, .variations .swatchesCon.list .swatch {
    display: inline-flex !important;
}

.variations .swatches-con.list .swatch .text, .variations .swatchesCon.list .swatch .text {
    height: 46px !important;
    line-height: 46px !important;
    width: 100%;
    border-radius: 4px;
    font-size: 1.3em !important;
    overflow: hidden;
}

.variations .swatches-con.list .swatch.selected .text {
    border: 1px solid #000 !important;
    background-color: #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    color: #fff !important;
    font-size: 1.3em !important;
}

.variations .swatches-con.list .swatch.available:hover .text {
    border: 1px solid #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    font-size: 1.3em !important;
}

/* ================================================ */
/* Content: Others  */
/* ================================================ */
.promo-header {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #fff;
    background-color: #cd1042;
    padding-top: 11px;
    font-size: 1em;
    letter-spacing: 0.5px;
}

.homepage-carousel-caption {
    margin: 0;
    position: absolute;
    bottom: 7%;
    left: 7%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: left;
    font-weight: 400;
    text-transform: none;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    color: #fff;
}

.homepage-carousel-caption h2 {
    font-size: 3em;
    font-weight: 700;
    text-align: left;
    margin: 0;
    line-height: 1.5em;
    text-transform: uppercase;
}

.homepage-button {
    padding: 20px;
    font-family: 'Open Sans';
    float: left;
    background-color: #cd1042;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    border: none;
    border-radius: 30px;
    -webkit-transition: background-color 0.15s ease-out;
    -moz-transition: background-color 0.15s ease-out;
    -o-transition: background-color 0.15s ease-out;
    transition: background-color 0.15s ease-out;
}

.homepage-button:hover {
    background-color: #1B262C;
    color: #fff;
}

.fa-chevron-right:before {
    content: "\f054";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
}

.slider-arrows2 .owl-controls div {
    background-color: transparent;
}

.page-content h2 {
    font-size: 2em;
    text-transform:uppercase;
}

.form-list .field {
    position: relative;
}

/* ================================================ */
/* Footer */
/* ================================================ */
/*Footer*/
ul.bullet li {
    background: none;
    padding-left: 0px;
    text-transform: capitalize;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.5px;
}

#footer ul li a {
    text-decoration: none;
    line-height: 1.75em;
    color: #fff;
    font-size: 1.2em;
    font-weight: 400;
}

#footer ul li a:hover {
    border-bottom: 1px solid #b3b3b3;
}

h6.block-title.heading {
    color: #ffffff;
    font-size: 2em;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.footer-top-container {
    background-color: #333333;
    margin-top: 30px;
}

.footer-top-container img {
    opacity: 1;
    -webkit-transition: opacity 0.15s ease-out;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}

.footer-top-container img.main-partner-logo {
    margin: 5px 0;
    opacity: 0.5;
}

.footer-top-container img.main-partner-logo:hover {
    opacity: 0.2;
}

.footer-bottom-container {
    background-color: transparent;
}

.footer-bottom-container .section.clearer {
    padding: 0;
}

.footer-copyright {
    color: #b3b3b3;
    text-transform: capitalize;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.5px;
    font-size: 1em;
    font-weight: 400;
}

/*Footer Copyright*/
.footer-copyright {
    display: none;
}

.copyright1 {
    background: transparent;
    text-transform: capitalize;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.5px;
    font-size: 1em;
    font-weight: 400;
    color: #b3b3b3;
}

.footer-container {
    background-color: #333333;
    color: #fff;
    margin-top:60px;
}

.footer-container2 {
	margin: 0 11%;	
}

/** extra **/
.item-user-links {
    display: none;
}

.header .logo strong {
    position:relative;
}
.main-container span.fa {
    margin:5px 0!important;
}
.quick-compare .fa {
	color:#777777;
	padding:0 !important;	
}
.module-category-filters .grid12-0, .module-header-multi .grid12-0 {
    vertical-align:top;
}
.nav-search, .nav-cart, .nav-logo {
    float:none !important;
    display:inline-block !important;
}
.form-search .button {
    color:#444444333!important;
    margin:5px 0!important;
}
.dropdown-toggle.cover>div {
    padding:0;
}
.nav-search.fly-out {
    margin:0;
}

.mm-panel_opened .mh-filters-btn {
    display: none !important;
}

@media only screen and (max-width:960px) {
    .nav-logo {
        width:200px;
        float:left !important;
        vertical-align:bottom;
        text-align:left;
        padding:10px 0;
        line-height:1em;
    }
    .display-mode-grid .sidebar-wrapper.mmenu-hide {
        display:block !important;
    }
    .display-mode-grid .sidebar-wrapper.mmenu-hide .sidebar-filter {
        display:none !important;
    }
}
@media only screen and (max-width:767px) {
    #new-advanced-search-box .results-right .products ul li.grow {
        width:48% !important;
        padding:5px 0;
        text-align:center;
    }
}

/* width > 960px */
@media only screen and (min-width: 960px) {
    /* Hide */
    .hide-above-960 { display:none !important; }
}

/* General */

div[class*=" module-"] .grid12-0, div[class^=module-] .grid12-0 {
	float:none;
	margin:0 auto;	
}
.header-top-container .fa {
	margin:0 auto !important;	
}
.sat-midsection {
	margin:35px 1% 0 1%;
	text-align:center;	
}
.sat-midsection .nested-container .grid-container {
	margin: 0 auto;
	width:100%;
	display:flex;
	justify-content:center;
}
.sat-midsection p {
	font-size:18px;
	line-height:1.2em;
}
.sat-midsection .section1 {
	width:60%;
	padding:20px;
}
.sat-midsection .section2 {
	width:18%;
	background:#ce1142;
	padding:2px;
}
.sat-midsection .section1, .sat-midsection .section2 {
	display:inline-flex;
	vertical-align:top;
	float:unset;
	min-height:175px;
	text-align:left;
}
.sat-midsection .section1 {
	background:#f1f2f2;
}
.top-banner .grid-container {
	min-height:480px;
}
.owl-prev {
	position: absolute;
    bottom: 46%;
}
.popular-parts .owl-prev {
	margin-left:1px;	
}
.owl-next {
	position: absolute;
    right: 0;
    bottom: 46%;	
}
.owl-prev .fa-chevron-left, .owl-next .fa-chevron-right {
	background: #5c5b5b;
    color: #fff;
    padding: 10px 5px 10px 10px;
    font-style:unset;
    cursor:pointer;
}
.quad-section .owl-next .fa-chevron-right, .quad-section .owl-prev .fa-chevron-left,.popular-parts .owl-next .fa-chevron-right, .popular-parts .owl-prev .fa-chevron-left {
	background: #fff;
    color: #cd1040;
    border-color: #ececec;
    border-width: 1px;
    border-style: solid;
}
.owl-pagination {
	display:none;	
}
.sat-icon-img img {
	display: inline-block;
    vertical-align: middle;
    max-height: 28px;
    margin: 7px 0px 0.5em 0em;
}
.sat-midsection .sat-icon-img img {
	margin: 7px 0px 0.5em 1em;
	max-height: 27px;
}
.sat-midsection .section2 h2 {
	display:inline-block;
	vertical-align:middle;
	margin: 0.3em 0 0.3em 0.3em;
}
.sat-midsection .section2 .section-header {
	background:#fff;	
}
.sat-midsection .section2 p {
	padding:15px;
	margin: 0 auto;
	color:#fff;
}
.sat-midsection .section2 .section-button {
	text-align:center;
}

.sat-midsection .section2 .section-button button {
	background: #fff;
    padding: 15px 50px;
    text-transform: uppercase;
    color: #ce1141;
    font-size: 2em;
    font-weight: bold;
    border: none;
    user-select: none;
    cursor:pointer;	
}
.popular-parts {
	width:82.5%;
	margin:30px 0% 30px 8.7%;
}
.popular-parts .owl-prev .fa-chevron-right, .popular-parts  .owl-next .fa-chevron-left {
	background: #fff;
    color: #ce1142;
    border-style: solid;
    border-color: #e7e8e8;
    border-width: 1px 0 1px 1px;
    padding: 10px 5px 10px 10px;
}

.popular-parts .owl-next .fa-chevron-left {
	border-width: 1px 1px 1px 0px;	
}

.popular-parts p {
	position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: bold;	
}
.popular-parts .grid-column-wrapper {
	overflow:hidden;	
}

.section-title {
	border:0;
	text-transform:uppercase;
	font-size:2em;
	text-align:left;
	margin-bottom:-28px;
}
.popular-parts .owl-stage-outer {
	border-color: #ececec;
    border-style: solid;
    border-width: 1px 1px 1px 0;
    box-sizing:border-box;	
}

.top-banner .owl-stage-outer,.catalogs .owl-stage-outer {
	border:0;	
}
.popular-parts .owl-carousel .owl-item img {
	border-left-color: #ececec;
    border-left-style: solid;
    border-left-width: 1px;
}
.quad-section .old-price,.quad-section .price-per-unit, .quad-section .special-price .price-label  {
	display:none;	
}
.quad-section .price {
	width:100%;
	font-size:16px;
	display:inline-block;
	color:#444 !important;
}
.quad-section .products-grid .product-name {
	margin:2px auto;
	width:100%;
	display:inline-block;	
	font-size: 18px;
    font-weight: 700;
}
.quad-section .products-grid .price-box {
	margin:0 auto;	
}
.quad-section .left .owl-carousel .owl-item img, .quad-section .left .owl-carousel .owl-item, .quad-section .left .owl-carousel .owl-item .item {
	height:100%;	
}
.quad-section-hd .owl-carousel .owl-item {
	float:unset !important;
	display:inline-block !important;	
}
.quad-section-hd .owl-carousel .owl-item {
    text-align: center;
    margin: 0px auto 0 auto;
    vertical-align:top;
    /*height: auto !important;*/
}
.quad-section .itemslider {
	margin:0;	
}
.quad-section .left .owl-stage-outer, .quad-section .left .owl-carousel, .quad-section .left .owl-carousel .owl-stage {
	height:100% !important;	
}
.quad-section-hd .xm-owl-carousel {
	text-align:center;	
}
.quad-section-hd .owl-stage-outer {
	border-color: #ececec;
    border-width: 1px 1px 1px 2px;
    border-style: solid;
    box-sizing:border-box;
}
.quad-section-hd .owl-stage-outer img {
	max-width:200px;
	margin:0 auto;
	padding-top:10px;	
}
.quad-section-hd  .add-to-links {
	display:none;	
}
.catalogs {
	width:83%;
	margin:30px 0 30px 8.4%;	
}
.quad-section {
  	margin: 30px 0% 30px 9.5%;
    width: 82.8%;
}
.quad-section .section-title {
	margin-left:-10px;	
}
.quad-section .left, .quad-section .right {
	display: inline-block;
    margin: 0;
    float: unset !important;
    vertical-align: top;
    width: 49%;
}
.quad-section .right {
	margin-left:-2px;
}

.quad-section .right .owl-stage {
	height:100%;
}

.quad-section .nested-container .grid-container {
	display:flex;
}
.quad-section .right .itemslider, .quad-section .right .owl-stage-outer {
	height:100%;
}

.quad-section-hd .itemslider-wrapper {
	position:relative;
	height:100%;
}
.quad-section-hd .line-vertical {
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #ececec;
  left: 50%;
  top: 0;
}
.quad-section-hd .line-horizontal {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ececec;
  left: 0;
  top: 50%;
}

.quad-section .left button {
	position: absolute;
    bottom: 50px;
    left: 10%;
    color: #fff;
    background: #cd1040;
    padding: 25px 0px;
    font-size: 2em;
    min-width: 80%;	
    border:none;
    cursor:pointer;
}
.isPublic .b2b {
  display: none;
}
.catalogs .item div {
	position:absolute;
	top:90px;
	left:65px;	
}

.catalogs p {
	font-size: 3em;
    text-transform: uppercase;
    font-weight: 650;
    text-align: left;
    line-height: 1.25em;
    color: #ce1241;	
}

.catalogs h3 {
	font-size:3.5em;
	font-weight:bold;
	color:#cd1040;
	text-transform:uppercase;
}
.catalogs .item .view {
	position:relative;
	color:#cd1040;
	font-size:3em;
	font-weight:100;	
}
.sat-contact-us {
	font-size:1.2em;
}
.sat-contact-us li {
	line-height:1.75em;	
}
.footer-links-sat .item-default .heading {
	display:none;	
}
.footer-top-container .footer-top .section {
	float:unset !important;	
}
.social-sat img {
	max-height:58px;
	margin:0 12px 0 0;	
}

.social-sat .fa, .social-sat .fa-brands {
	margin:0 12px;
	font-size:2em;	
}
.social-sat .fa-twitter, .social-sat .fa-brands {
	margin:0 12px 0 0;	
}
.social-sat a {
	color:#fff;	
}
.social-sat a:hover {
	color:#cd1040;
}
.sidebar-filter,.sidebar-category {
	text-align:left;	
}

.sidebar dd {
	padding:0 0 0 0.5em;	
}

.sidebar li {
	line-height:1.75em;	
}

.sidebar-filter .block-content {
	padding-left:10px;	
}
.my-account-sidebar li {
	line-height:1em !important;	
}
.sidebar .block-title strong {
	font-weight:600;	
}

.sidebar .block-content {
	font-size:13px;	
}

.sidebar .accordion-style1 li a {
	font-size:13px;
	line-height:1em;	
}
.category-products-grid {
	display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 1px;
    background: #ececec;
    border-style: solid;
    box-sizing: border-box;
    border-width: 1px 1px 0 1px;
    border-color:#ececec;
}
.category-products-grid .item {
	width:auto;
	padding:10px !important;
}
.category-products-grid .item .price-box {
	font-size:1.2em;	
}
.sat-cat-misc {
	margin-top:30px;	
}
.category-products-grid .module-category-product-listing .add-cart-wrapper  {
	position:static !important;	
}
.category-title,.pager {
	border:0;	
}
.pager .pages li.current {
	background-color:#fff;	
}
.pager .pages li a {
	background-color:#cfcfcf;	
}
.pager .pages strong {
	float:unset;	
}
.module-category-misc [class^="grid"] {
  display:inline-block;
  float:unset;
  vertical-align:middle;
}
.module-category-misc .grid12-4:nth-child(2),.module-category-misc .grid12-4:nth-child(3) {
	text-align:center;
	width:auto;
} 
.module-category-misc .grid12-4:nth-child(4) {
	text-align:right;
}
.module-category-product-listing button.button span {
	margin:5px auto;
}
.module-category-misc {
	text-align:left;	
}
.products-list .btn-cart {
	max-width:113px;	
}
.module-category-misc .category-title {
	text-align:center;	
}
.add-cart-wrapper .more-info {
	display:none;	
}
.add-cart-wrapper .qty-wrapper,.add-cart-wrapper .add-cart-button {
	display:inline-block;
	vertical-align:middle;
}
.module-category-product-listing__actionButtons {
	text-align:right;	
}

.module-category-product-listing__productDesc {
	text-align:left;	
	font-weight:900;
}

.module-category-product-listing__productSecDec {
	text-align:left;	
}

.module-category-product-listing__price {
	text-align:right;	
}

.item-code,.sat-product-details .product-name h4 {
	font-weight:700;
	color:#ce1141;	
}

.sat-product-details .module-product-details__uom, .sat-product-details .module-product-details__qtyInput, .sat-product-details .module-product-details__uom, .sat-product-details .module-product-details__cartButton, .sat-product-details .module-product-details__misc {
	display:inline-block;
	width:auto !important;
	float:unset;
	vertical-align:middle;
	margin:0 5px;
}

.sat-product-details .add-cart-wrapper {
	padding:0;	
}

.sat-product-details .product-shop {
	text-align:left;	
}

.sat-product-details .module-product-details__cartButton {
	text-align:left;	
}

.sat-product-details .special-price {
	font-weight:900;	
}

.sat-product-details #unit-messure-wrapper {
	font-size:19px;	
}

.sat-product-details .old-price {
	margin:0;	
}

.old-price {
	text-decoration:line-through;	
}

.sat-product-details .module-product-details__qtyInput input {
	height:50px;
	width:50px !important;
	color:#444;
}

.sat-product-details .module-category-product-listing__productDesc .product-name {
	font-weight:900;
	margin:0;	
}

.sat-product-details .module-product-details__title .product-name h1 {
	margin:0;	
}
.prop65 {
	float: left;
    text-align: left;
    margin: 21px 14% 0 1%;
    font-style: italic;
    font-size: 14px;
}
input.qty {
	padding:13px 0;
}

.special-price,.regular-price {
	font-weight:900;	
}

.item-code-label::after {
  content: " #";
  margin-right:-2px;
}

.satellite-cat-col {
	display: inline-block;
    width: 33%;
    vertical-align: top;	
}
.price-box {
	text-transform:capitalize !important;
	font-size:14px;
}

.display-mode-list .old-price, .display-mode-list .special-price {
	margin:2px auto;
}

.special-price .price, .special-price .price-label {
	color:#444 !important;	
}

.display-mode-grid .module-category-product-listing__price, .display-mode-grid .module-category-product-listing__productDesc, .display-mode-grid .module-category-product-listing__actionButtons {
	text-align:left;
	margin-left:20px;
}

.module-category-product-listing__productSecDec {
	margin:5px auto;
	font-size:13px;
}

.display-mode-grid .add-cart-wrapper {
	text-align:left !important;	
}

.hidethis {
	display:none !important;	
}

.module-category-product-listing .product-name {
	font-size:18px;
	font-weight:700;
	margin-bottom:0;
}

.item-code,.price-box {
	margin:5px auto 0 auto;
	font-size:14px;	
}

.products-list .item-code {
	font-size:16px;	
}

/* product details favorites */
.sat-product-details .favorites, .sat-product-details .compare {
	display: inline-block;
    background: #fff;
    color: #fff;
    font-size: 1.9em;
    font-weight: 500;
    line-height: 0;
    padding: 2px 0;	
}
span.icon.icon-color-productview {
	background-color:#000;
	color:#fff;	
}
.main-container .favorites .icon {
	border:1px;
	border-radius:25px !important;
	background-color: #000000;
	color:#ffffff;	
}
.main-container .favorites .icon:hover {
	background-color:#ffffff;	
}
.sat-product-details .favorites:hover {
	background:#fff;	
}

.sat-product-details .feature.first {
	margin: 0;
    width: 100%;
    display: inline-block;
}

.sat-product-details .favorites a { 
	color:#fff;
	background:transparent;
}

.sat-product-details .favorites a:hover { 
	color:#fff;
	background:transparent;
}

.sat-product-details .fa-heart {
	font-size:15px !important;
	color:#444;	
}

.sat-product-details .feature-icon-hover:hover span.icon.icon-color-productview {
	color:#5c5c5c!important;
}

.sat-product-details .feature-icon-hover:hover span.icon.icon-color-productview {
	background-color:#fff;	
}

.sat-product-details .module-product-details__tab1 {
	width:90%;
	margin:15px auto;	
}

.sat-product-details .module-product-details__tab1 p {
	text-align:left;
	font-size:1.2em;	
}
/* more gen stuff */
.sat-product-details #unit-messure-wrapper label {
	display:none;	
}
.sat-product-details .price-per-unit {
	display:none;	
}
.quad-section .itemslider .item {
	padding:0px 10px;
}
/* other parts you might need */
.product-shop .owl-stage-outer {
	border:0;	
}
.product-shop .section-title {
	margin:15px auto;
}

.product-shop .owl-carousel .owl-item {
	border-width: 1px;
    border-style: solid;
    border-color: #ececec;
    box-sizing: border-box;
}

.product-shop .owl-carousel .product-name {
	font-weight:700;	
}
.product-shop .owl-carousel  .price-box .regular-price .price {
	color:#444;
	margin-left:-4px;
}

.product-shop .owl-carousel  .price-box, .product-shop .owl-carousel .price-box * {
	font-size:16px;
	font-weight:600;
	margin:0 auto;
}
/* product tabs */

.gen-tabs .tabs a.current {
	background:	#ce1141;
	color:#fff;
}

.gen-tabs-style1 .tabs a.current {
	border:1px solid transparent;
	margin-top:0;
	padding-top:0;
}

.tabs-panels .panel .std {
	padding: 30px;
    background: #e6e6e6;
    margin: 8px 18px;
    text-align: left;
    font-size:16px;
    font-weight:100;
    line-height:28px;
}

.gen-tabs-style1 .tabs-panels {
	border:2px solid #ce1141;	
}

.module-product-tabs {
	margin:25px 2%;
	width:96%;
}

#footer button {
	border:0;
	margin:15px auto;	
}

#footer button a:hover {
	background:	#fff;
	color:#ce1142;
}

#footer button a {
	margin: 0 auto;
    background: #ce1142;
    color: #fff;
    border: 0;
    font-size: 1.2em;
    text-transform: uppercase;
    padding: 10px;	
}

.mmenu-category-wrapper {
	position:absolute !important;
	left:0;
}

.mmenu-filters-wrapper {
	position:absolute !important;
	left:30px;
}

#paymentScreen .form-list .input-box {
	text-align:left;	
}

.account-login p.required, .account-login .form-list {
	text-align:center;
	margin:0 27%;
}

.sidebar .block-content #filter_list_box dd ol {
	min-height:500px;	
}

.category-title-item {
    display: inline-block;
    width: 17.82%;
    border-color: #ececec;
    border-style: solid;
    border-width: 1px;
    padding: 1%;
}
.category-title-item h3 {
	font-weight:600;
	margin:10px 0 10px 20px;
	font-size:18px;	
}
.category-title-items {
	text-align:left;
	margin:15px auto 60px auto;	
}
.category-title-item button {
	margin-left:15px;	
}

.module-category-misc .grid12-12 {
	margin:0 auto;
	width:100%;
} 

.sat-cat-grid {
	display:inline-block;
	float:unset;
	margin-top:10px;	
}

.sat-cat-filters {
	height:100vh;	
}
.sat-cat-misc .module-category-misc {
	text-align:right;	
}

.order_details_section, .my-account .order-date,.my-account .title-buttons, .my-account .title-buttons h1 {
	text-align:left !important;	
}

.myaccountmenu {
	float:left !important;
    margin-left:20px !important;
    margin-top:1px !important;
}

.account-login .buttons-set button, .account-login .buttons-set a{
	float:unset !important;	
}

.registered-users .buttons-set a {
	display:inline-block;
	width:100%;	
}

@media (max-width: 1620px) {
  .quad-section .left button {
  	font-size:2em;	
  }
  .category-products-grid {
  	grid-template-columns: repeat(3,1fr);
  }
}
@media (max-width: 1500px) {
  .category-title-item {
  	width:15%;
  	margin:10px;
  	height:auto;
  }
  .category-title-items {
  	text-align:center;	
  }
  .quad-section-hd .itemslider-wrapper {
  	min-height:650px;	
  }
}
@media (max-width: 1360px) {
  .footer-container2 {
	margin: 0 4%;	
  }
  .sat-category-menu {
  	width:50%;	
  }
  .quad-section .left button {
  	font-size:2em;	
  }
  .top-banner .grid-container {
		min-height:unset;	
	}
  .social-sat .fa,.social-sat .fa-brands {
	   margin:6px;
   }
}
@media (max-width: 1100px) {
	.site-notifacition {
		position: fixed;
    top: 0;
    width: 100%;
    left: 0;
	}
	.account-login .form-list, .account-login p.required {
		margin:0 auto;	
	}
	 .footer-container2 {
	margin: 0 3% !important;	
  }
	.tabs-panels {
		width:96%;	
	}
	.gen-tabs.accor .acctab {
		display: block;
	    background: #ececec;
	    padding: 10px 0;
	}
	.gen-tabs.accor .current {
		background:#ce1141;
		color:#fff;
	}
	.prop65 {
		margin:5% 5% 0 5%;	
	}
	#inline-search-results {
		position:fixed !important;
		top:199px !important;	
	}
	.quad-section .left button {
		font-size:1.5em;
		left:11%;	
	}
	.category-products-grid {
	  	grid-template-columns: repeat(1,1fr);
	 }
	.quad-section-hd .itemslider-wrapper {
  	min-height:unset;	
  }
	.owl-stage-outer {
		border:0;
	}
  .quad-section-hd .owl-stage-outer {
  	border:0;	
  }
  .quad-section,.catalogs {
  	margin:0 auto;
   }
  .popular-parts .owl-carousel .owl-item img {
  	border:0;	
  }
  .quad-section .left .item {
  	max-height: 176px;
    background-position: center;
    background-size: cover;
  }
  .quad-section .left img {
  	opacity:0;	
  }
  .quad-section .nested-container .grid-container {
  	display:inline;	
  }
  .link-svg-div {
  	text-align:center;	
  }
  .quad-section,.catalogs,.popular-parts {
  	overflow:hidden;
  	width:100%;	
  }
  .popular-parts {
  	margin:0 auto;	
  }
  .sat-midsection {
  	margin: 0 auto;	
  }
  .quad-section .left button {
  	font-size:1.5em;
    padding: 10px 0;
    bottom: 70px;
  }
  .quad-section .left, .quad-section .right {
  	width:100%;
  	margin: 0 auto;
  }
  .top-banner .grid-container {
  	min-height:unset;	
  }
  .sat-midsection .section1, .sat-midsection .section2 {
  	margin:0 auto;
  	width:98% !important;
  	padding:2%;
  	display:inline-block;
  }
  .quad-section .right .owl-stage-outer {
  	height:auto;
  }
  .quad-section-hd .line-horizontal {
  	display:none;	
  }
  .sat-midsection { 
  	margin-top:20px;	
  }
  
  .sat-midsection .nested-container {
  	margin: 0 auto;
  	width:100%;	
  }
  .footer .section .grid12-* {
  	display: inline-block;
    float: unset;
    margin-left: 1%;
    margin-right: 1%;
    width: 98%;
    margin-top: 15px;	
  }
  .social-sat .fa,.social-sat .fa-brands {
  	font-size:2em;	
  }
  .search-wrapper {
  	text-align:center;	
  }
  #search {
  	width:90% !important;
  	display:inline-block;	
  }
}
@media (max-width: 961px) {
	.section-title.padding-right  {
		padding-right:20px !important;	
	}
	.sat-product-details .product-shop {
	text-align:center;	
	}
	.category-title-item {
    	width: 70%;
    	margin: 15px auto;
	}
.sat-cat-filters {
	height:unset;	
}
.sidebar {
		display:none;	
	}
}
@media (max-width: 762px) {
	.main {
		padding:10px 0 0 0;	
	}
	.sat-product-details {
	margin:15px 0 0 0;	
   }
	.product-img-column {
		width:90% !important;
		margin:2% 5%;
	}
	.product-shop h1 {
		font-size:25px;
		margin:10px auto;	
	}
	.product-shop .product-name h4 {
		margin:10px auto;	
	}
	.sat-product-details .product-name h4 {
		text-align:center;
	}
	.module-product-details__qtyInput {
		    width: auto !important;
    vertical-align: middle;
    margin-left: 46%;
	}
	.module-product-details__uom {
		width: auto !important;
	    vertical-align: middle;
	    margin-left: 5px;
	}
.add-to-links li {
	float:unset;	
}
.addto-gaps-right li {
	margin:0;	
}	
  .add-cart-wrapper {
  	text-align:center;	
  }
	.category-products-grid .item {
		width: auto !important;	
	}
  .category-products-grid {
	  grid-template-columns: repeat(1,1fr); 
   }
  .popular-parts .owl-stage-outer {
  	border-width: 1px 1px 1px 1px;	
  }
  .quad-section .section-title {
  	margin-left:0;	
  }
  .sat-midsection .nested-container .grid-container {
  	display:inline-block;	
  }
  .popular-parts {
  	margin:25px auto;	
  }
  .catalogs .owl-carousel .owl-item img {
    width: auto;
    max-height: 350px;
    margin: 0 auto;
    text-align: center;	
  }
  .header .logo {
  	max-width:unset;	
  }
  .sat-midsection .section1, .sat-midsection .section2 {
  	margin:0 auto;
  	width:97% !important;
  	padding:1%;
  	display:inline-block;
  }
 .sat-midsection {
 	margin:0 auto;	
 }
  .section-title {
  	font-size:2em;	
  }
  .catalogs, .quad-section {
  	margin-top:50px;	
  }
  .quad-section-hd .line-vertical {
  	display:none;	
  }
  .catalogs .grid-column-wrapper {
  	margin-top:25px;	
  }
  .footer .heading {
  	margin-top:30px;	
  }
  .footer .copyright-sat {
  	margin-top:30px;
  }
}

@media (max-width: 450px) {
	.section-title {
		font-size:1.5em;
	}	
}